import styled from 'styled-components';

import Heading from 'components/heading';
import Text from 'components/text';
import { useGetVersion } from 'version/utils/hooks';

import AddForm from './components/AddForm';
import List from './components/List';

const TestTenants = () => {
  const { isApproved, isLoading } = useGetVersion();

  if (isApproved || isLoading) return null;

  return (
    <>
      <StyledHeading level={3}>Test Your App</StyledHeading>
      <Text secondary>
        Prior to submitting your app, test it by adding tenants below. Your app
        will display for them in the App Store and can be installed by any user
        with Admin Owner access on their account.
      </Text>
      <AddForm />
      <List />
    </>
  );
};

const StyledHeading = styled(Heading)`
  margin-bottom: 5px;
`;

export default TestTenants;
