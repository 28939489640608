export const OBJECT_TYPE = {
  ALLOCATION: 'ALLOCATION',
  CART: 'CART',
  CLUB: 'CLUB',
  CLUB_MEMBERSHIP: 'CLUB_MEMBERSHIP',
  CLUB_SHIPMENT: 'CLUB_SHIPMENT',
  CLUB_SIGNUP: 'CLUB_SIGNUP',
  CLUB_PACKAGE: 'CLUB_PACKAGE',
  COLLECTION: 'COLLECTION',
  COUPON: 'COUPON',
  CUSTOMER: 'CUSTOMER',
  CUSTOMER_ADDRESS: 'CUSTOMER_ADDRESS',
  CUSTOMER_CREDIT_CARD: 'CUSTOMER_CREDIT_CARD',
  DEPARTMENT: 'DEPARTMENT',
  EMAIL: 'EMAIL',
  FORM: 'FORM',
  GIFT_CARD: 'GIFT_CARD',
  INVENTORY: 'INVENTORY',
  LOYALTY: 'LOYALTY',
  META_DATA_CONFIG: 'META_DATA_CONFIG',
  NOTE: 'NOTE',
  ORDER: 'ORDER',
  PERSONALIZATION_BLOCK: 'PERSONALIZATION_BLOCK',
  POS_PROFILE: 'POS_PROFILE',
  PRODUCT: 'PRODUCT',
  PRODUCT_TEMPLATE: 'PRODUCT_TEMPLATE',
  PROMOTION: 'PROMOTION',
  PROMOTION_SET: 'PROMOTION_SET',
  QUERY: 'QUERY',
  REFUND: 'REFUND',
  REPORT: 'REPORT',
  RESERVATION: 'RESERVATION',
  SETTING: 'SETTING',
  SHIPPING: 'SHIPPING',
  TAG: 'TAG',
  TRASH: 'TRASH',
  TAX: 'TAX',
  TOKEN_MIGRATION: 'TOKEN_MIGRATION',
  VENDOR: 'VENDOR',
  WEB_HOOK: 'WEB_HOOK',
  WINE_APPELLATION: 'WINE_APPELLATION',
  WINE_VARIETAL: 'WINE_VARIETAL'
};

export const OBJECT_TYPE_TRANSLATED = {
  ALLOCATION: 'Allocation',
  CART: 'Cart',
  CLUB: 'Club',
  CLUB_MEMBERSHIP: 'Club Membership',
  CLUB_SHIPMENT: 'Club Shipment',
  CLUB_SIGNUP: 'Club Signup',
  CLUB_PACKAGE: 'Club Package',
  COLLECTION: 'Collection',
  COUPON: 'Coupon',
  CUSTOMER: 'Customer',
  CUSTOMER_ADDRESS: 'Customer Address',
  CUSTOMER_CREDIT_CARD: 'Customer CreditCard',
  DEPARTMENT: 'Department',
  EMAIL: 'Email',
  FORM: 'Form',
  GIFT_CARD: 'Gift Card',
  INVENTORY: 'Inventory',
  LOYALTY: 'Loyalty',
  META_DATA_CONFIG: 'MetaData Config',
  NOTE: 'Note',
  ORDER: 'Order',
  PERSONALIZATION_BLOCK: 'Personalization Block',
  POS_PROFILE: 'Pos Profile',
  PRODUCT: 'Product',
  PRODUCT_TEMPLATE: 'Product Template',
  PROMOTION: 'Promotion',
  PROMOTION_SET: 'Promotion Set',
  QUERY: 'Query',
  REFUND: 'Refund',
  REPORT: 'Report',
  RESERVATION: 'Reservation',
  SETTING: 'Setting',
  SHIPPING: 'Shipping',
  TAG: 'Tag',
  TAX: 'Tax',
  TRASH: 'Trash',
  TOKEN_MIGRATION: 'Token Migration',
  VENDOR: 'Vendor',
  WEB_HOOK: 'WebHook',
  WINE_APPELLATION: 'Wine Appellation',
  WINE_VARIETAL: 'Wine Varietal'
};

export const REQUEST_TYPE = {
  READ: 'READ',
  FULL: 'FULL'
};

export const REQUEST_TYPE_TRANSLATED = {
  READ: 'Read',
  FULL: 'Full'
};
